import { openAppointmentModal } from './modal-appointment';

import heroHome720URL from '../video/hero-home-720.mp4';
import heroHome480URL from '../video/hero-home-480.mp4';
import heroAbout480URL from '../video/hero-about-480.mp4';
import heroAbout720URL from '../video/hero-about-720.mp4';
import heroServices480URL from '../video/hero-services-480.mp4';
import heroServices720URL from '../video/hero-services-720.mp4';

const heroSectionAppointmentBtn = document.getElementById(
  'hero-appointment-modal'
);

heroSectionAppointmentBtn.addEventListener('click', openAppointmentModal);

function checkScreenWidth() {
  const heroHomeSection = document.querySelector('.homepage-hero-section');
  const heroAboutSection = document.querySelector('.about-hero-section');
  const heroServicesSection = document.querySelector('.services-hero-section');

  if (heroHomeSection) {
    function setVideoSource() {
      const video = document.querySelector('.homepage-hero-video');
      const viewportWidth = window.innerWidth;

      if (viewportWidth <= 834) {
        video.src = heroHome480URL;
      } else {
        video.src = heroHome720URL;
      }

      video.load();
    }

    // Встановити початковий відео-джерело при завантаженні сторінки
    setVideoSource();

    // Оновити відео-джерело при зміні розміру вьюпорта
    window.addEventListener('resize', setVideoSource);
  } else if (heroAboutSection) {
    function setVideoSource() {
      const video = document.querySelector('.aboutpage-hero-video');
      const viewportWidth = window.innerWidth;

      if (viewportWidth <= 834) {
        video.src = heroAbout480URL;
      } else {
        video.src = heroAbout720URL;
      }

      video.load();
    }

    // Встановити початковий відео-джерело при завантаженні сторінки
    setVideoSource();

    // Оновити відео-джерело при зміні розміру вьюпорта
    window.addEventListener('resize', setVideoSource);
  } else if (heroServicesSection) {
    function setVideoSource() {
      const video = document.querySelector('.services-hero-video');
      const viewportWidth = window.innerWidth;

      if (viewportWidth <= 834) {
        video.src = heroServices480URL;
      } else {
        video.src = heroServices720URL;
      }

      video.load();
    }

    // Встановити початковий відео-джерело при завантаженні сторінки
    setVideoSource();

    // Оновити відео-джерело при зміні розміру вьюпорта
    window.addEventListener('resize', setVideoSource);
  }
}

checkScreenWidth();

window.addEventListener('resize', checkScreenWidth);
